import React from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';
import {
  TicketCommentFullFragmentDoc,
  TicketCommentTypes,
  useGetTicketDiscussionQuery,
  useOnTicketCommentCreatedSubscription,
  useOnTicketCommentUpdatedSubscription
} from 'generated/types';
import TicketComment from 'components/ticket/TicketDiscussion/TicketComment';
import TicketEvent from 'components/ticket/TicketDiscussion/TicketEvent';
import TicketReply from 'components/ticket/TicketDiscussion/TicketReply';
import { Col, Row, Skeleton } from 'antd';

interface Props {
  ticketId: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 64px;
`;

gql`
  query GetTicketDiscussion($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      id
      ticketId
      machineIdRaw
      retailerIdRaw
      comments {
        ...TicketCommentFull
      }
    }
  }
  ${TicketCommentFullFragmentDoc}
`;

gql`
  subscription OnTicketCommentCreated($ticketId: Int!) {
    onTicketCommentCreated(ticketId: $ticketId) {
      ...TicketCommentFull
      ticket {
        id
        ticketId
        machineIdRaw
        retailerIdRaw
        comments {
          ...TicketCommentFull
        }
      }
    }
  }
  ${TicketCommentFullFragmentDoc}
`;

gql`
  subscription OnTicketCommentUpdated($ticketId: Int!) {
    onTicketCommentUpdated(ticketId: $ticketId) {
      ...TicketCommentFull
    }
  }
  ${TicketCommentFullFragmentDoc}
`;

const TicketDiscussion: React.FC<Props> = (props) => {
  const { ticketId } = props;

  const { data, loading } = useGetTicketDiscussionQuery({
    variables: {
      ticketId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });

  useOnTicketCommentUpdatedSubscription({
    variables: data
      ? {
          ticketId
        }
      : undefined,
    skip: !data
  });

  useOnTicketCommentCreatedSubscription({
    variables: data
      ? {
          ticketId
        }
      : undefined,
    skip: !data
  });

  return (
    <Container>
      {loading && (
        <Row gutter={[16, 16]} justify={'center'}>
          <Col xs={18}>
            <Skeleton loading={true} />
          </Col>
        </Row>
      )}

      {data?.ticket.comments.map((comment) => {
        switch (comment.commentType) {
          case TicketCommentTypes.Opened:
          case TicketCommentTypes.Closed:
          case TicketCommentTypes.ClosedAsNotCompleted:
          case TicketCommentTypes.Reopened:
          case TicketCommentTypes.Commented:
            return <TicketComment comment={comment} key={comment.id} />;
          case TicketCommentTypes.Assigned:
          case TicketCommentTypes.Unassigned:
          case TicketCommentTypes.SetTitle:
          case TicketCommentTypes.SetSeverity:
          case TicketCommentTypes.SetType:
          case TicketCommentTypes.SetDue:
          case TicketCommentTypes.Moved:
          case TicketCommentTypes.AddedTag:
          case TicketCommentTypes.RemovedTag:
          case TicketCommentTypes.AddedOrderLine:
          case TicketCommentTypes.UpdatedOrderLine:
          case TicketCommentTypes.DeletedOrderLine:
          case TicketCommentTypes.SetOrderLineAccounted:
            return <TicketEvent comment={comment} key={comment.id} />;
          default:
            return null;
        }
      })}
      <TicketReply ticketId={ticketId} />
    </Container>
  );
};

export default TicketDiscussion;

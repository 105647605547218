import {
  TicketCommentFullFragment,
  TicketCommentTypes,
  TicketSeverity,
  useGetTicketTypesQuery
} from 'generated/types';
import React, { useMemo } from 'react';
import { Avatar, Typography } from 'antd';
import { useIntl } from 'react-intl';
import useFormatTools from 'i18n/useFormatTools';
import styled from 'styled-components';
import UserAvatar from 'components/user/UserAvatar/UserAvatar';
import { UserOutlined } from '@ant-design/icons';
import RelativeTime from 'components/i18n/RelativeTime/RelativeTime';
import getTicketCommentTypeIcon from 'components/ticket/TicketDiscussion/getTicketCommentTypeIcon';
import TicketSeverityCircle from 'components/ticket/TicketSeverity/TicketSeverityCircle';
import commonMessages from 'components/i18n/commonMessages';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { parseISO } from 'date-fns';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import Tag from 'components/lib/Tag/Tag.tsx';

function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}

const parseTicketSeverity = (raw: string): TicketSeverity | undefined => {
  return enumFromStringValue(TicketSeverity, raw);
};

const Container = styled.div`
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-direction: row;
`;

const AvatarCol = styled.div`
  flex: 0 0 auto;
`;

const ContentCol = styled.div`
  flex: 1 1 auto;
  font-size: 12px;
  line-height: 20px;
`;

interface Props {
  comment: TicketCommentFullFragment;
}

const SetTitleMessage: React.FC<Props> = (props) => {
  const { comment } = props;
  const intl = useIntl();
  const { formatUserName } = useFormatTools();

  const updatedTitleRendered =
    comment.content?.trim().length || 0 > 0
      ? comment.content
      : intl.formatMessage(commonMessages.empty).toLowerCase();

  return (
    <>
      {intl.formatMessage(
        {
          id: 'ticket_event.set_title',
          defaultMessage: '{user} {icon} set title to {title} {relativeTime}'
        },
        {
          user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
          icon: getTicketCommentTypeIcon(comment.commentType),
          title: <Typography.Text strong={true}>{updatedTitleRendered}</Typography.Text>,
          relativeTime: <RelativeTime dateRaw={comment.date} />
        }
      )}
    </>
  );
};

const AssignedMessage: React.FC<Props> = (props) => {
  const { comment } = props;
  const intl = useIntl();
  const { formatUserName } = useFormatTools();

  const isSelfAssign = comment.user?.__typename === 'User' && comment.user.name === comment.content;

  return (
    <>
      {isSelfAssign && (
        <>
          {intl.formatMessage(
            {
              id: 'ticket_event.assign_user_self',
              defaultMessage: '{user} self assigned {relativeTime}'
            },
            {
              user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
              relativeTime: <RelativeTime dateRaw={comment.date} />
            }
          )}
        </>
      )}
      {!isSelfAssign && (
        <>
          {intl.formatMessage(
            {
              id: 'ticket_event.assign_user',
              defaultMessage: '{user} assigned {assignedUser} {relativeTime}'
            },
            {
              user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
              assignedUser: <Typography.Text strong={true}>{comment.content}</Typography.Text>,
              relativeTime: <RelativeTime dateRaw={comment.date} />
            }
          )}
        </>
      )}
    </>
  );
};

const UnassignedMessage: React.FC<Props> = (props) => {
  const { comment } = props;
  const intl = useIntl();
  const { formatUserName } = useFormatTools();

  return (
    <>
      {intl.formatMessage(
        {
          id: 'ticket_event.unassign_user',
          defaultMessage: '{user} unassigned ticket {relativeTime}'
        },
        {
          user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
          assignedUser: <Typography.Text strong={true}>{comment.content}</Typography.Text>,
          relativeTime: <RelativeTime dateRaw={comment.date} />
        }
      )}
    </>
  );
};

const SetDueDateMessage: React.FC<Props> = (props) => {
  const { comment } = props;
  const intl = useConnectIntl();
  const { formatUserName } = useFormatTools();
  const { formatDate } = useDateFormatTools();

  const displayDate = useMemo(() => {
    try {
      const rawDate = comment.content;
      const parsedDate = rawDate ? parseISO(rawDate) : undefined;
      return parsedDate ? formatDate(parsedDate) : rawDate;
    } catch (err) {
      return comment.content;
    }
  }, [comment.content, formatDate]);

  return (
    <>
      {intl.formatMsg(
        {
          id: 'ticket_event.set_due_date',
          defaultMessage: '{user} set due date to {dueDate} {relativeTime}'
        },
        {
          user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
          dueDate: <Typography.Text strong={true}>{displayDate}</Typography.Text>,
          relativeTime: <RelativeTime dateRaw={comment.date} />
        }
      )}
    </>
  );
};

const MovedMessage: React.FC<Props> = ({ comment }) => {
  const intl = useConnectIntl();
  return (
    <>
      {intl.formatMsg(
        {
          id: 'ticket_event.moved',
          defaultMessage: 'Ticket was moved from machine {machineId} {relativeTime}'
        },
        {
          relativeTime: <RelativeTime dateRaw={comment.date} />,
          machineId: comment.content?.replace(':', ' to ')
        }
      )}
    </>
  );
};

const SetSeverityMessage: React.FC<Props> = (props) => {
  const { comment } = props;
  const intl = useIntl();
  const { formatUserName } = useFormatTools();
  const rawSeverity = comment.content;

  if (!rawSeverity) {
    console.warn('Could not parse TicketSeverity, empty value');
    return null;
  }

  const parsedSeverity = parseTicketSeverity(rawSeverity);

  if (!parsedSeverity) {
    console.error(`Could not parse TicketSeverity from value ${rawSeverity}`);
    return null;
  }

  return (
    <>
      {intl.formatMessage(
        {
          id: 'ticket_event.set_severity',
          defaultMessage: '{user} set severity to {severityCircle} {relativeTime}'
        },
        {
          severityCircle: <TicketSeverityCircle severity={parsedSeverity} />,
          user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
          relativeTime: <RelativeTime dateRaw={comment.date} />
        }
      )}
    </>
  );
};

const SetTypeMessage: React.FC<Props> = ({ comment }) => {
  const intl = useConnectIntl();
  const { formatUserName } = useFormatTools();
  const { data } = useGetTicketTypesQuery({
    variables: {
      machineType: undefined
    }
  });

  const ticketTypeIdFromComment: number | undefined = comment.content
    ? isNaN(+comment.content)
      ? undefined
      : +comment.content
    : undefined;

  let translatedTicketType: string | undefined = undefined;

  if (data && ticketTypeIdFromComment !== undefined) {
    // get i18n ticket type text given int
    const match = data.allTicketTypes.find((c) => c.ticketTypeId === ticketTypeIdFromComment);
    if (match) {
      translatedTicketType = intl.formatMsg({
        id: match.nameKeyI18n
      });
    }
  }

  if (translatedTicketType) {
    return (
      <div>
        {intl.formatMessage(
          {
            id: 'ticket_event.set_type_unknown_type',
            defaultMessage: '{user} changed ticket type to {ticketType} {relativeTime}'
          },
          {
            user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
            ticketType: <Typography.Text strong={true}>{translatedTicketType}</Typography.Text>,
            relativeTime: <RelativeTime dateRaw={comment.date} />
          }
        )}
      </div>
    );
  } else {
    return (
      <div>
        {intl.formatMessage(
          {
            id: 'ticket_event.set_type',
            defaultMessage: '{user} changed ticket type {relativeTime}'
          },
          {
            user: <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>,
            relativeTime: <RelativeTime dateRaw={comment.date} />
          }
        )}
      </div>
    );
  }
};

const TicketEvent: React.FC<Props> = (props) => {
  const { comment } = props;
  const intl = useConnectIntl();
  const { formatUserName } = useFormatTools();

  return (
    <Container>
      <AvatarCol>
        {comment.user && <UserAvatar user={comment.user} />}
        {!comment.user && <Avatar size={'small'} icon={<UserOutlined />} />}
      </AvatarCol>
      <ContentCol>
        {comment.commentType === TicketCommentTypes.SetTitle && (
          <SetTitleMessage comment={comment} />
        )}
        {comment.commentType === TicketCommentTypes.Assigned && (
          <AssignedMessage comment={comment} />
        )}
        {comment.commentType === TicketCommentTypes.Unassigned && (
          <UnassignedMessage comment={comment} />
        )}
        {comment.commentType === TicketCommentTypes.SetType && <SetTypeMessage comment={comment} />}
        {comment.commentType === TicketCommentTypes.SetSeverity && (
          <SetSeverityMessage comment={comment} />
        )}
        {comment.commentType === TicketCommentTypes.SetDue && (
          <SetDueDateMessage comment={comment} />
        )}
        {comment.commentType === TicketCommentTypes.Moved && <MovedMessage comment={comment} />}

        {comment.commentType === TicketCommentTypes.AddedTag && (
          <>
            {intl.formatMsg(
              { id: 'ticket_event.added_tag', defaultMessage: '{user} added tag {tag}' },
              {
                user: (
                  <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>
                ),
                tag: <Tag>{comment.content}</Tag>
              }
            )}
          </>
        )}
        {comment.commentType === TicketCommentTypes.RemovedTag && (
          <>
            {intl.formatMsg(
              { id: 'ticket_event.removed_tag', defaultMessage: '{user} removed tag {tag}' },
              {
                user: (
                  <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>
                ),
                tag: <Tag>{comment.content}</Tag>
              }
            )}
          </>
        )}
        {comment.commentType === TicketCommentTypes.AddedOrderLine && (
          <>
            {intl.formatMsg(
              { id: 'ticket_event.added_order_line', defaultMessage: '{user} added a order line' },
              {
                user: (
                  <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>
                )
              }
            )}
          </>
        )}
        {comment.commentType === TicketCommentTypes.UpdatedOrderLine && (
          <>
            {intl.formatMsg(
              {
                id: 'ticket_event.updated_order_line',
                defaultMessage: '{user} updated a order line'
              },
              {
                user: (
                  <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>
                )
              }
            )}
          </>
        )}
        {comment.commentType === TicketCommentTypes.DeletedOrderLine && (
          <>
            {intl.formatMsg(
              {
                id: 'ticket_event.deleted_order_line',
                defaultMessage: '{user} deleted a order line'
              },
              {
                user: (
                  <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>
                )
              }
            )}
          </>
        )}
        {comment.commentType === TicketCommentTypes.SetOrderLineAccounted && (
          <>
            {intl.formatMsg(
              {
                id: 'ticket_event.set_order_line_accounted',
                defaultMessage: '{user} marked a order line as accounted'
              },
              {
                user: (
                  <Typography.Text strong={true}>{formatUserName(comment.user)}</Typography.Text>
                ),
                id: comment.content
              }
            )}
          </>
        )}
      </ContentCol>
    </Container>
  );
};

export default TicketEvent;

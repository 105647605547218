import React, { useMemo } from 'react';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { gql } from '@apollo/client/core';
import { FullCountryFragment, useGetAllCountriesQuery } from 'generated/types';

gql`
  query GetAllCountries {
    allCountries {
      ...FullCountry
    }
  }
`;

export interface CountryOptionType extends DefaultOptionType {
  country: FullCountryFragment;
}

function TypedCountrySelect<T extends string>({
  children,
  ...props
}: SelectProps<T, CountryOptionType>) {
  return <Select<T, CountryOptionType> {...props}>{children}</Select>;
}

type OtherSelectProps = Omit<SelectProps<string, CountryOptionType>, 'options'>;

const CountrySelect: React.FC<OtherSelectProps> = (props) => {
  const { loading, value, ...rest } = props;
  const intl = useIntl();

  const { data, loading: countriesLoading } = useGetAllCountriesQuery();

  const options = useMemo(() => {
    return data?.allCountries?.map((c) => ({
      label: c.nameNative,
      value: c.id,
      key: c.countryCode,
      country: c
    }));
  }, [data?.allCountries]);

  return (
    <TypedCountrySelect
      style={{ minWidth: 200 }}
      options={options}
      loading={loading || countriesLoading}
      value={countriesLoading ? undefined : value}
      {...rest}
    />
  );
};

export default CountrySelect;
